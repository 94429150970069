<template>
  <div class="box">
    <div class="headline">区域管理</div>
    <div class="content">
      <p>进入区域管理界面，【基础设置】→【区域管理】，如下图</p>
      <img src="../../assets/specificationImgs/img23.png" alt width="100%" />
      <p>页面打开默认显示所有区域，可以通过输入《编号》或《名称》模糊筛选区域信息，下方显示的是人员的详细信息。</p>
      <p>
        1）新增：直接在页面右侧输入《区域名称》，《区域编号》点击“确定”即可。<br />
        2）修该：从左侧选中一行《区域详细信息》，按需修改员工信息，点击“确定”。<br />
        3）删除：从列表选中区域，点击“红色垃圾桶”删除。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
